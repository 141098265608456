module.exports = [{
      plugin: require('../node_modules/@joyfulelement/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"duration":1000},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SoStronk - Win Real Cash and Rewards for Playing Games","short_name":"SoStronk","start_url":"/","background_color":"#0F1922","theme_color":"#242B34","display":"standalone","icon":"src/images/logo-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"77dc68d0ffd8e145a4ec3c09a6cc4e47"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-QKBMYKGGG9","AW-16555292730","AW-16585042424"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel-lazy/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"446284011391096","timeout":3666},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
